<ll-over-title>
    <arte-home-showcasebox-barra [pagina]="pagina"></arte-home-showcasebox-barra>
</ll-over-title>

<div class="alert alert-warning alert-dismissible" role="alert">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <strong>¡Advertencia!</strong>
    No ha confirmado su correo electrónico, por favor verificarlo.
</div>
<div class="row">
    <div class="col-md-4 col-sm-6">
        <usuario-showcasebox></usuario-showcasebox>
        <br />
    </div>
    <div class="col-md-4 col-sm-6">
        <notificaciones-showcasebox></notificaciones-showcasebox>
        <br />
    </div>
    <div class="col-md-4 col-sm-6">
        <arte-home-showcasebox [pagina]="pagina"></arte-home-showcasebox>
        <br />
        <noticias-alumnos-showcasebox></noticias-alumnos-showcasebox>
    </div>
</div>