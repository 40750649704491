import { urls } from "../config/urls.prod"
import { AppEnvironment } from "@puntaje/shared/core"

export const environment: AppEnvironment = {
    production: true,
    endpoints: {
        base: urls.baseUrl,
        nebulosa: urls.nebuUrl,
        achievements: urls.achievementsUrl,
        carreras: urls.carrerasUrl
    },
    googleAnalyticsPropertyId: {},
    googleTagManagerPropertyId: {
        alumnos: "G-7220CRB7WN"
    },
    domain: {
        profesores: "docentes.xstem.ogr.cl",
        alumnos: "www.xstem.ogr.cl",
        admin: "admin.xstem.ogr.cl"
    },
    facebookAnalyticsPropertyId: {},
    facebook: {
        appId: null,
        apiVersion: null
    }
}

export const oldEnvironment = {
    production: true,
    baseUrl: urls.baseUrl,
    nebuUrl: urls.nebuUrl,
    achievementsUrl: urls.achievementsUrl,
    facebookAppId: null,
    facebookApiVersion: null,
    domain: {
        profesores: "docentes.xstem.ogr.cl",
        alumnos: "www.xstem.ogr.cl",
        admin: "admin.xstem.ogr.cl"
    }
}
