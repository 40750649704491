import {
    AmazonType,
    ApiType,
    AppConfiguration,
    AppType,
    AsignaturaType,
    EvaluacionTipoMap,
    HojaRespuestaType,
    PlataformaType
} from "@puntaje/shared/core"

export class BaseConfig implements AppConfiguration {
    navegacion = {
        urlHistorial: ["/evaluaciones/resultados/historial"]
    }

    generar = {
        generar: true,
        intrumentosRecomendados: true,
        pruebasPropias: false,
        buscador: true
    }

    registro = {
        enableCarreras: null,
        universidades: [],
        amount: 3,
        enableNivel: true,
        enableColegio: true,
        enableTelefono: true,
        nivelObligatorio: false,
        colegioObligatorio: false,
        enableFechaNacimiento: true,
        nombresObligatorio: true,
        apellidoPaternoObligatorio: true,
        telefonoObligatorio: true,
        fechaNacimientoObligatorio: true
    }
    app = {
        assets: {
            logoLarge: "assets/img/logos/logoLarge.png",
            logoMedium: "assets/img/logos/logoMedium.png",
            logoSmall: "assets/img/logos/logoSmall.png",
            logoLargeLight: "assets/img/logos/logoLargeLight.png",
            logoMediumLight: "assets/img/logos/logoMediumLight.png",
            logoSmallLight: "assets/img/logos/logoSmallLight.png",
            logoSquare: "assets/img/logos/logoSquare.png",
            logoSquareLight: "assets/img/logos/logoSquareLight.png",
            streamingOffline: ""
        },
        name: "profesores",
        loader: "aprendolibre2",
        routeUser: null,
        routeUserInfo: null,
        paths: {
            pendingInfo: "/pending_info",
            landing: "/landing"
        },
        baseStyle: {
            navbar: {
                logo: "logoSmall",
                logoMobile: "logoSquare"
            },
            footer: {
                logo: "logoSmall"
            },
            landing: {
                backgroundImage:
                    "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/clones/304aa24a2d79dbfc340d06626449798dImagen-Landing-min.png",
                logo: "logoLarge",
                subtitle: null,
                subtitleHasShadow: null,
                logoFooter: "logoSmall"
            },
            sharedLayout: {
                backgroundImage:
                    "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/clones/be1a7eb036a9763bdc5d7f46cdb597f5Imagen-para-registro-min.png",
                logo: "logoLarge"
            }
        },
        navigationByApp: {
            alumnos: [
                {
                    icon: "libro2-o",
                    name: "base_topmenu.alumno.blog",
                    route: "/blog"
                },
                {
                    icon: "planificacion-o",
                    name: "base_topmenu.alumno.planes_de_clase",
                    route: "/plan_clases"
                },
                {
                    icon: "dialogos",
                    name: "base_topmenu.alumno.biblioteca",
                    route: "/biblioteca"
                }
            ],
            profesores: [
                {
                    icon: "editar-prueba",
                    name: "base_topmenu.profe.evaluar",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "upload",
                    name: "base_topmenu.profe.corregir",
                    route: "/evaluacion_instancias/subir"
                },
                {
                    icon: "resultados",
                    name: "base_topmenu.profe.resultados",
                    route: "/evaluaciones/resultados"
                },
                {
                    icon: "planificacion-o",
                    name: "base_topmenu.profe.planes_de_clase",
                    route: "/plan_clases"
                },
                {
                    icon: "megafono-o",
                    name: "base_topmenu.profe.mis_noticias",
                    route: "/noticias"
                },
                {
                    icon: "planificacion-o",
                    name: "base_topmenu.profe.planes_clase_predefinido",
                    route: "/plan_clases_predefinidos"
                }
            ]
        },
        showcasesByApp: {
            alumnos: [
                {
                    orden: 0,
                    nombre: "Datos Usuario",
                    columna: 0,
                    identificador: "usuario-showcasebox"
                },
                {
                    orden: 0,
                    nombre: "Menu box",
                    columna: 1,
                    identificador: "base-menu-showcasebox"
                },
                {
                    orden: 0,
                    nombre: "Notificaciones",
                    columna: 2,
                    identificador: "notificaciones-showcasebox"
                },
                {
                    orden: 1,
                    nombre: "Noticias",
                    columna: 2,
                    identificador: "noticias-alumnos-showcasebox"
                }
            ],
            profesores: []
        },
        menuConfig: {
            tipo: "horizontal"
        }
    } as AppType

    amazon = {
        s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/",
        s3BucketUriNebu: "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/"
    } as AmazonType

    api = {
        checkTokenPath: "/check_token",
        authPath: "/authenticate"
    } as ApiType

    asignaturas = [
        {
            id: null,
            asignatura: "Matemática",
            icono: "matematica",
            clase: "matematica",
            abreviacion: "Matemática",
            asignaturaPlataforma: "Matemática",
            evaluacionTipo: ["prueba de transición", "paes"],
            materialVisible: true
        },
        {
            asignatura: "Lenguaje",
            icono: "lenguaje",
            clase: "lenguaje",
            abreviacion: "Lenguaje",
            asignaturaPlataforma: "Lenguaje",
            evaluacionTipo: ["prueba de transición", "paes"],
            materialVisible: true
        },
        {
            asignatura: "Orientación",
            icono: "hoja",
            clase: "ed-ciudadana",
            abreviacion: "Orientación",
            asignaturaPlataforma: "Orientación",
            evaluacionTipo: ["prueba de transición", "paes"],
            materialVisible: true
        },
        {
            asignatura: "Historia y Ciencias Sociales",
            icono: "historia",
            clase: "historia",
            abreviacion: "Historia",
            asignaturaPlataforma: "Historia y Ciencias Sociales",
            evaluacionTipo: ["prueba de transición", "paes"],
            materialVisible: true
        },
        {
            asignatura: "Ciencias Naturales",
            icono: "ciencias",
            clase: "ciencias",
            abreviacion: "Ciencias",
            asignaturaPlataforma: "Ciencias Naturales",
            evaluacionTipo: ["prueba de transición", "paes"],
            materialVisible: true
        }
    ] as AsignaturaType[]

    plataforma = {
        name: "xstem",
        id: null,
        pais: "chile",
        lugarTipo: "Comuna",
        lugarLabel: "Comuna",
        identificadorUsuario: ["RUT"],
        identificadorEstablecimiento: ["rbd"],
        grupoUsuarioAlias: "curso",
        clasificacionTipoNivel: "curso",
        info: {
            social: {
                facebook: "https://www.facebook.com/XstemLA/",
                twitter: "https://twitter.com/xstemla",
                youtube: "https://www.youtube.com/channel/UCTbenAnWeDR_kyc3hbAAf_Q",
                instagram: "instagram.com/xstemla "
            },
            soporte: {
                mail: "info@xstemla.com",
                telefono: null
            },
            contacto: {
                mail: "info@xstemla.com",
                telefono: null
            },
            companyName: null,
            companySite: "https://xstemla.com"
        },
        evaluacionTipoAdaptiveDefault: "paes",
        evaluacionDefault: "paes",
        resumenGeneral: {
            curriculum: "curricular",
            generadores: [83, 15, 16, 17, 18, 84, 19, 20, 85]
        },
        tiposInstrumentoInformeUso: [],
        evaluacionTipos: ["prueba de transición", "paes"],
        añoInicial: 2020,
        periodos: [
            {
                label: "",
                fechaInicial: [0, 1],
                fechaFinal: [11, 31, 23, 59, 59]
            }
        ],
        streamingDefault: null,
        iconSet: {
            noticiasShowcase: "megafono-o",
            establecimientosShowcase: "establecimiento-o",
            cursosShowcase: "comunidad-o"
        },
        instrumentoMensualTipo: "curricular",
        tiposNotificacion: [
            {
                tipo: "curricular mexico",
                label: "Pruebas"
            },
            {
                tipo: "duda",
                label: "Dudas"
            },
            {
                tipo: "respuesta_correcta_duda",
                label: "Respuestas Correctas Dudas"
            },
            {
                tipo: "respuesta_duda",
                label: "Respuestas Dudas"
            },
            {
                tipo: "retroalimentacion",
                label: "Retroalimentaciones"
            }
        ],
        sesionAlias: "logoSquareLight",
        identificadorUsuarioAlias: null,
        evaluacionResponder: "estandar",
        planClasesRoute: "/plan_clases",
        locale: "es-cl"
    } as PlataformaType

    evaluaciones = {
        "prueba de transición": {
            alias: "prueba de transición",
            cantidadPreguntas: 5,
            clasificaciones: {
                estadisticas: ["nivel 1 prueba de transición", "nivel 2 prueba de transición"],
                estadisticasEvolutivas: "nivel 1 prueba de transición,nivel 2 prueba de transición",
                desempenoEvaluacion: "nivel 1 prueba de transición,nivel 2 prueba de transición",
                groupBy: ["nivel 1 prueba de transición,nivel 2 prueba de transición"],
                clasificacionTiposTablaEspecificaciones: [
                    "nivel 1 prueba de transición",
                    "habilidad prueba de transición"
                ],
                methods: "clasificaciones",
                referencesTo: {
                    "nivel 2 prueba de transición": "nivel 1 prueba de transición"
                },
                configuration: [
                    {
                        name: "Nivel 1",
                        htmlId: "nivel1",
                        hasHijos: true,
                        clasificacionTipo: "nivel 1 prueba de transición"
                    }
                ],
                cambiarPregunta: ["nivel 1 prueba de transición"]
            },
            clasificacionDistribucion: "ejes",
            clasificacionMaterial: {
                clasificacion: "nivel 1 prueba de transición",
                label: "eje"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "nivel 1 prueba de transición",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "nivel 2 prueba de transición",
                    alumnos: false,
                    compuesto: true,
                    profesores: true,
                    informeAnual: false
                },
                {
                    nombre: "habilidad prueba de transición",
                    alumnos: false,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                }
            ],
            cursoTipo: "curso PSU",
            cursos: [
                {
                    label: "7º Medio",
                    clasificacion: "Séptimo Básico"
                },
                {
                    label: "8º Medio",
                    clasificacion: "Octavo Básico"
                },
                {
                    label: "1º Medio",
                    clasificacion: "Primero Medio"
                },
                {
                    label: "2º Medio",
                    clasificacion: "Segundo Medio"
                },
                {
                    label: "3º Medio",
                    clasificacion: "Tercero Medio"
                },
                {
                    label: "4º Medio",
                    clasificacion: "Cuarto Medio"
                }
            ],
            cursosMaterial: [],
            estrellas: [
                {
                    max: 266,
                    min: 150
                },
                {
                    max: 383,
                    min: 267
                },
                {
                    max: 500,
                    min: 384
                },
                {
                    max: 617,
                    min: 501
                },
                {
                    max: 734,
                    min: 618
                },
                {
                    max: 850,
                    min: 735
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            rangoCalificacion: {
                min: 150,
                tickInterval: 50,
                max: 850
            },
            streamingChannelAlias: "Clases Prueba de transición",
            planPersonalClasificacionMateriales: []
        },
        paes: {
            alias: "PAES",
            cantidadPreguntas: 5,
            clasificaciones: {
                estadisticas: ["Eje PAES"],
                estadisticasEvolutivas: ["Eje PAES"],
                desempenoEvaluacion: "Eje PAES",
                groupBy: ["Eje PAES,Unidad Temática PAES"],
                clasificacionTiposTablaEspecificaciones: ["Eje PAES", "Unidad Temática PAES"],
                methods: "clasificaciones",
                referencesTo: {
                    "Unidad Temática PAES": "Eje PAES"
                },
                configuration: [
                    {
                        name: "Eje PAES",
                        htmlId: "Eje_PAES",
                        hasHijos: true,
                        clasificacionTipo: "Eje PAES"
                    }
                ],
                cambiarPregunta: ["nivel 1 prueba de transición"]
            },
            clasificacionDistribucion: "ejes",
            clasificacionMaterial: {
                clasificacion: "Eje PAES",
                label: "eje"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "Eje PAES",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "Unidad Temática PAES",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: false
                },
                {
                    nombre: "Habilidad PAES",
                    alumnos: false,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                }
            ],
            cursoTipo: "curso PSU",
            cursos: [
                {
                    label: "7º Medio",
                    clasificacion: "Séptimo Básico"
                },
                {
                    label: "8º Medio",
                    clasificacion: "Octavo Básico"
                },
                {
                    label: "1º Medio",
                    clasificacion: "Primero Medio"
                },
                {
                    label: "2º Medio",
                    clasificacion: "Segundo Medio"
                },
                {
                    label: "3º Medio",
                    clasificacion: "Tercero Medio"
                },
                {
                    label: "4º Medio",
                    clasificacion: "Cuarto Medio"
                }
            ],
            cursosMaterial: [],
            estrellas: [
                {
                    max: 266,
                    min: 100
                },
                {
                    max: 383,
                    min: 267
                },
                {
                    max: 500,
                    min: 384
                },
                {
                    max: 617,
                    min: 501
                },
                {
                    max: 734,
                    min: 618
                },
                {
                    max: 1000,
                    min: 735
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            rangoCalificacion: {
                min: 100,
                tickInterval: 50,
                max: 1000
            },
            streamingChannelAlias: "Clases Prueba de transición",
            planPersonalClasificacionMateriales: []
        }
    } as EvaluacionTipoMap

    instrumentosPredefinidos = {
        zonas: ["Columna 1", "Columna 2", "Columna 3"]
    }

    hojaRespuesta = {
        sectores: [
            [1, 2],
            [2, 3],
            [3, 4],
            [5, 2],
            [2, 7],
            [7, 4],
            [5, 6],
            [6, 7],
            [7, 8]
        ],
        alternativas: 5,
        rutFormaByMarkers: {
            10: {
                rutMarkerUp: 6,
                rutMarkerBottom: 10,
                rutCols: 11,
                formaMarkerUp: 9,
                formaMarkerBottom: 6,
                formaCols: 11
            }
        },
        alignByMarkers: {
            10: [1, 5]
        },
        validMarkers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    } as HojaRespuestaType

    mensajesLogin = {
        idPlaceholder: "Email o NUIP",
        invalidLoginId: "Debes incluir un correo electrónico o NUIP válido.",
        invalidLoginMessage1:
            "<strong>La combinación de correo electrónico/contraseña o NUIP/contraseña es incorrecta.</strong> Las contraseñas de ",
        invalidLoginMessage2:
            ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.'
    }
}
